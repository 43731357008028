import React, { useState, Component } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import "./login.css";
import getCookie from "../getCookie";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom'

// React components
import * as Yup from "yup";
import { Formik } from "formik";

let csrfToken;

class Login extends Component {
  state = {
    signInError: "",
    pageRedirectStatus: false
  };

  handleLoginSubmit = (userData, setSubmitting) => {
    this.setState({signInError: ""})
    csrfToken = getCookie("csrftoken");
    
    fetch(`/license/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
        // "Access-Control-Allow-Origin": "http://13.76.220.143:3000",
      },
      body: JSON.stringify(userData)
    })
      .then(res => res.json())
      .then(response => {
        setSubmitting(false)
        if(response.success){
          localStorage.setItem("login", true)
          this.setState({ pageRedirectStatus: true })
        }
        else{
          this.setState({ signInError: response.msg });
        }
      })
      .catch(error => console.error(error));
  };

  render() {
    if(this.state.pageRedirectStatus)
      return <Redirect to="/home"/>
    return (
      <div className="Login" style={{ padding: '0px' }}>
      <nav className="navbar navbar-light bg-light">
        <a className="navbar-brand" href="#">
          <img src="favicon.ico" width="30" height="30" style={{ margin: '0px 10px' }} className="d-inline-block align-top" alt="" />
            VAST License Server
        </a>
      </nav>

      <div className="d-flex justify-content-center" style={{ paddingTop: '30px' }}>
        <div className="col-sm-6">
          <div className="card">
            <h5 className="card-header d-flex justify-content-center" >Login</h5>
            <div className="card-body">
              <Formik
                initialValues={{ email: "", password: ""}}
                validationSchema={Yup.object().shape({
                  email: Yup.string().required("Required, Please enter Email").email("Invalid Email"),
                  password: Yup.string().required("Required, Please enter Password"),
                })}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting, setFieldValue }) => {
                  this.handleLoginSubmit(values, setSubmitting);
                }}
              >
                {({ values, errors, isValidating, isSubmitting, handleChange, handleSubmit }) => (
                <form  style={{ maxWidth: "480px" }} onSubmit={handleSubmit}>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text"
                        id="inputGroup-sizing-default"
                        style={{ padding: '0px 27px' }}>Email</span>
                    </div>
                    <input
                      name="email"
                      className="form-control"
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      value={values.email}
                      onChange={handleChange}
                      autoFocus={true}
                    />
                  </div>
                  <p className="text-danger" style={{fontSize: 14}}>{errors.email}</p>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text"
                        id="inputGroup-sizing-default">Password</span>
                    </div>
                    <input
                      name="password"
                      type="password"
                      className="form-control"
                      aria-label="Default"
                      aria-describedby="inputGroup-sizing-default"
                      value={values.password}
                      onChange={handleChange} />
                  </div>
                  <p className="text-danger" style={{fontSize: 14}}>{errors.password}</p>
                  <p className="text-danger" style={{fontSize: 14}}>{this.state.signInError}</p>
                  <Button
                    block bsSize="large"
                    disabled={isValidating || isSubmitting}
                    type="submit"
                  >
                    Login
                  </Button>
                </form>
              )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
};

export default Login;