import React from 'react';
import { NavLink } from "react-router-dom";

function SideNavigation() {
	return (
		<ul className="nav flex-column textColorNotChange mt-4 ml-2">
			<NavLink className="navItem my-2 dashoard-nav" to='/home' exact activeClassName='is-active' style={{color: 'rgba(0,0,0,.9)'}}>Home</NavLink>
			<NavLink className="navItem my-2 dashboard-nav" to='/dashboard' exact activeClassName='is-active' style={{color: 'rgba(0,0,0,.9)'}}>Dashboard</NavLink>
		</ul>
	)
}

export default SideNavigation;