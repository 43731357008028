import React, { useState, Component } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import getCookie from "../getCookie";
import { connect } from "react-redux";
import { GenerateKey } from "../GenerateKey/generatekeymodal";
import {Dashboard} from "../Dashboard/dashboard";
import { Redirect, Link } from "react-router-dom";

class HomePage extends Component {
    state = {
        loggedIn: true
    }

    componentDidMount(){
        this.setState({loggedIn: localStorage.getItem('login')})
    }

    render() {
        if(!this.state.loggedIn){
            return <Redirect to="/login" />
        }
        return (
            <div className="Login" style={{ padding: '0px' }}>
                {/* <nav className="navbar navbar-light bg-light">
                    <a className="navbar-brand" href="#">
                    <img src="favicon.ico" width="30" height="30" style={{ margin: '0px 10px' }} className="d-inline-block align-top" alt="" />
                        VAST License Server
                    </a>
                </nav> */}
                <div className="row">
                    <div className="col-sm-6" style={{padding: '35px'}}>
                        <div className="card text-center">
                        <div className="card-body">
                            <h5 className="card-title">Generate License Key</h5>
                            <p className="card-text">Generate a New License Key for Customer.</p>
                            <button
                                className='btn btn-primary'
                                style={{ height: '35px', marginTop: '7px' }}
                                data-toggle="modal"
                                data-target="#genrate_key_modal"
                                data-backdrop="static">Generate</button>
                            {/* <a href="#" className="btn btn-primary">Generate</a> */}
                            <GenerateKey />
                        </div>
                        </div>
                    </div>
                    <div className="col-sm-6" style={{padding: '35px'}}>
                        <div className="card text-center">
                        <div className="card-body">
                            <h5 className="card-title">License Key Dashboard</h5>
                            <p className="card-text">View all the License Keys that are Generated/Activated</p>
                            {/* <a href="#" className="btn btn-primary">Dashboard</a> */}
                            <Link to="/dashboard"><button className='btn btn-primary' style={{ height: '35px', marginTop: '7px' }}>Dashboard</button></Link>
                        </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }

};

export default(HomePage);