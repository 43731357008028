import React, {useState} from 'react';
// import { useDarkMode } from '../components/darkTheme/darkTheme';
import { ThemeProvider } from 'styled-components';
// import { lightTheme, darkTheme } from '../components/darkTheme/theme';
// import { GlobalStyles } from '../components/globalcss/globalCss';
import { NavLink, Redirect } from "react-router-dom";

function NavBar() {
	//to change theme
	// const [theme, toggleTheme] = useDarkMode();
    // const themeMode = theme === 'light' ? lightTheme : darkTheme;
    const [logout, setLogout] = useState(false)

    const handleLogout = () => {
        localStorage.removeItem('login')
        setLogout(true)
    }
    if(logout){
        return <Redirect to="/login" />
    }
	return (
        <div>
            <nav className="navbar navbar-light bg-light">
                <a className="navbar-brand" href="#">
                    <img src="favicon.ico" width="30" height="30" style={{ margin: '0px 10px' }} className="d-inline-block align-top" alt="" />
                        VAST License Server
                </a>
                <div className="ml-auto mr-5">
                    <button style={{borderRadius: '12px', backgroundColor: '#f7f7f7', fontSize: '12px'}} onClick={handleLogout}>Logout</button>
                </div>

            </nav>
            {/* <nav className="navbar navbar-expand-lg navbar-light d-md-none">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbar">
                    <div className="navbar-nav">
                        <ul className="nav flex-column textColorNotChange mt-4 ml-2">
                            <NavLink className="navItem my-2 dashboard-nav" to='/' exact activeClassName='is-active'>Home</NavLink>
                            <NavLink className="navItem my-2 dashboard-nav" to='/dashboard' exact activeClassName='is-active'>Dashboard</NavLink>
                            <NavLink className="navItem my-2 dashboard-nav" to='/dashboard/events' exact activeClassName='is-active'>Events History</NavLink>
                            <NavLink className="navItem my-2 dashboard-nav" to='/dashboard/camera-service-time' exact activeClassName='is-active'>Camera Service Time</NavLink>
                            <NavLink className="navItem my-2 dashboard-nav" to='/dashboard/vast' exact activeClassName='is-active'>Vast System</NavLink>
                            <NavLink className="navItem my-2 dashboard-nav" to='/dashboard/camera-health' exact activeClassName='is-active'>Camera Health</NavLink>
                            <NavLink className="navItem my-2 dashboard-nav" to='/dashboard/non-critical-events' exact activeClassName='is-active'>Non Critical Events</NavLink>
                        </ul>
                    </div>
                </div>
            </nav> */}
        </div>
		
	);
}

export default NavBar