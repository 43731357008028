import React from 'react';
import NavBar from '../layout/topNavigation';
import SideNavigation from '../layout/sideNavigation';

const AdminLayout = ({ children }) => {

  return (
    <div className="body App container-fluid">
      <div className="row">
        <div className="col-md-12 col-sm-12 p-0">
          <NavBar />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="row">
            <div className="col-md-2 sideNavBorder d-none d-md-block">
              <SideNavigation />
            </div>
            <div className="col-md-10 col-sm-12 pt-4 pb-3">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminLayout;