import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import getCookie from "../getCookie";
import Redirect from "react-router-dom/Redirect";
import moment from 'moment'

// React components
import * as Yup from "yup";
import { Formik } from "formik";

let csrfToken;

export class GenerateKey extends Component {
  state = { copied: false };

  copyToClickboard = (e) => {
    // console.log(e.clientX)
    window.getSelection().removeAllRanges();
    var range = document.createRange();
    range.selectNode(e.target.parentElement.parentElement.firstChild);
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    this.setState({copied: true})
    setTimeout(()=>{
      this.setState({copied: false})
    }, 2000)
  };

  handleGenerateKeySubmit = (values, setSubmitting, setValues) => {
    const token = window.localStorage.getItem("token");
    csrfToken = getCookie("csrftoken");
    const { organization, licenseType, validity, permanent, vastId } = values;
    const data = { organization, license_type: licenseType, validity, permanent, vast_id: vastId }
    fetch(`/license/generatekey/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken,
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((response) => {
        setSubmitting(false)
        if (response.success) {
          setValues({...values, otp: response.OTP, mail:'mailto:?subject=VAST%20OTP&body='+response.OTP})
        }
      })
      .catch((error) => console.error(error));
  };
  
  errorMsg= (errors) => {
    let errorMsg = `Required, Please fill${errors.organization || ""}${errors.validity || ""}${errors.licenseType || ""}${errors. vastId || ""}`
    errorMsg = `${errorMsg.substring(0, errorMsg.length - 1)}.`
    return errorMsg
  }

  render() {
    const x = {
      30: "1 month",
      60: "2 month",
      90: "3 month",
      180: "6 month",
    };

    return (
      <div className="row">
        <div className="mx-auto col-sm-6">
          <div
            className="modal fade"
            id="genrate_key_modal"
          >
            <div
              className="modal-dialog modal-dialog-centered"
              role="document"
              style={{ maxWidth: "650px" }}
            >
              <Formik
                initialValues={{
                  organization: "",
                  licenseType: "",
                  validity: "",
                  permanent: false,
                  vastId: "",
                  otp: "",
                  mail: ""
                }}
                validationSchema={Yup.object().shape({
                  organization: Yup.string().required(" Organization name,"),
                  licenseType: Yup.string().required(" License type,"),
                  vastId: Yup.string().required(" Vast ID,"),
                  validity: Yup.string().when("permanent", {
                    is: val => !val,
                    then: Yup.string().required(" Expiration,")
                  })
                })}
                validateOnChange={false}
                onSubmit={(values, { setSubmitting, setValues }) => {
                  this.handleGenerateKeySubmit(values, setSubmitting, setValues);
                }}
              >
                {({
                  values,
                  errors,
                  isValidating,
                  isSubmitting,
                  handleChange,
                  handleSubmit,
                  resetForm,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} style={{minWidth: 600}}>
                    <div className="modal-content">
                      <div className="modal-header m-2">
                        <h3 className="mb-0">Generate License Key</h3>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={resetForm}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <div className="modal-body pt-0">
                        <div className="row m-1">
                          <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4 pl-2">
                            <label htmlFor="ExampleFormControlInput m-1">
                              Organization
                            </label>
                          </div>
                          <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8 pl-1">
                            <input
                              name="organization"
                              className="form-control form-control-sm"
                              type="text"
                              placeholder="Organization Name"
                              onChange={handleChange}
                              value={values.organization}
                            />
                          </div>
                        </div>

                        <div className="row m-1">
                          <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4 pl-2">
                            <label htmlFor="ExampleFormControlInput m-1">
                              Expiration Date
                            </label>
                          </div>
                          <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4 pl-1">
                            <input
                              name="validity"
                              className="form-control form-control-sm"
                              type="date"
                              style={{ width: "100%" }}
                              min = {moment(new Date()).format('YYYY-MM-DD')}
                              onChange={handleChange}
                              value={values.validity}
                              disabled={values.permanent === true ? true : false}
                            />
                          </div>
                          <div className="col-xs-2 col-lg-2 col-sm-2 col-md-2 mt-1">
                            <label htmlFor="ExampleFormControlInput m-1">
                              Permanent
                            </label>
                          </div>
                          <div className="col-xs-1 col-lg-1 col-sm-1 col-md-1 ">
                            <input
                              name="permanent"
                              className="form-control form-control-sm"
                              type="checkbox"
                              style={{ width: "auto" }}
                              onChange={(e) => {
                                handleChange(e)
                                setFieldValue("validity", "")
                              }}
                              checked={values.permanent}
                            />
                          </div>
                        </div>

                        <div className="row m-1">
                          <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4 pl-2">
                            <label htmlFor="ExampleFormControlInput m-1">
                              License Type
                            </label>
                          </div>
                          <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8 pl-1">
                            <select
                              name="licenseType"
                              onChange={handleChange}
                              id="inputState"
                              className="input-custom-heightqx03 form-control form-control-sm "
                              value={values.licenseType}
                            >
                              <option value="">Choose Type</option>
                              <option value="demo">Demo</option>
                              <option value="industrial">Industrial</option>
                              <option value="school">School</option>
                            </select>
                          </div>
                        </div>
                        <div className="row m-1">
                          <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4 pl-2">
                            <label htmlFor="ExampleFormControlInput m-1">
                              VAST ID
                            </label>
                          </div>
                          <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8 pl-1">
                            <input
                              name="vastId"
                              className="form-control form-control-sm"
                              type="text"
                              onChange={handleChange}
                              placeholder=" Vast Id"
                              style={{ width: "100%" }}
                              value = {values.vastId}
                            />
                          </div>
                        </div>
                        {values.otp ? (
                          <React.Fragment>
                            <div className="row m-1">
                              <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4 pl-2">
                                <label htmlFor="ExampleFormControlInput m-1">
                                  OTP
                                </label>
                              </div>
                              <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8 pl-1">
                                <p style={{textAlign: "left"}}>
                                  <span>{values.otp}</span>
                                  <span
                                    onClick={(e) => this.copyToClickboard(e)}
                                    style={{ cursor: "pointer", marginLeft: '20px' }}
                                  >
                                    <img src="https://img.icons8.com/carbon-copy/24/000000/copy.png" title="Copy" />
                                  </span>
                                  <a href={values.mail} >
                                    <img src="https://img.icons8.com/wired/24/000000/email.png" title="E-mail" />
                                  </a>
                                </p>
                              </div>
                            </div>
                            <p className="text-success" style={{fontSize: 14}}>
                              OTP generated successfully, Please reset before generating a new OTP
                            </p>
                          </React.Fragment>
                        ) : null}
                        {errors.organization || errors.validity || errors.licenseType || errors. vastId ? (
                          <p className="text-danger" style={{fontSize: 14}}>
                            {this.errorMsg(errors)}
                          </p>
                        ) : null}
                        {this.state.copied ? (
                          <p style={{position: 'absolute', top: 0, left: 0,padding: '1%', color: 'white', backgroundColor: 'green'}} >Copied !</p>
                        ): null}
                      </div>

                      <div className="modal-footer p-2 ali mr-4">
                        <button
                          className="btn btn-danger"
                          value="Cancel"
                          type="reset"
                          style={{ width: "100px" }}
                          onClick={resetForm}
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          value="Save Changes"
                          style={{ width: "150px", margin: "5px" }}
                          disabled={isValidating || isSubmitting || values.otp}
                        >
                          Generate Key
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(GenerateKey);
