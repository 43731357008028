import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import getCookie from "../getCookie";
import { Redirect } from 'react-router-dom';
import Moment from 'moment'
let csrfToken;

export class Dashboard extends Component {
    state = {
        licenseData: [],
        loggedIn: true
    }

    componentDidMount(){
        if(!localStorage.getItem('login')){
            this.setState({loggedIn: localStorage.getItem('login')})
        }
        else{
            fetch(`/license/dashboard/`)
                .then(res => res.json())
                .then(response => {
                    if(response.success){
                        this.setState({licenseData: response.Data})
                    }
                })
                .catch(error => console.error(error));
            }
        }

    render() {
        if(!this.state.loggedIn){
            return <Redirect to="/login" />
        }
        return (
            <div style={{width: '100%', overflow: 'auto'}} >
                <table class="table table-hover DivcolorWhite cusTable tableScrollDiv" style={{marginTop: "15px"}}>
                    <thead>
                        <tr style={{fontSize: '14px'}}>
                            <th scope="col">#</th>
                            <th scope="col">Organization</th>
                            <th scope="col">VAST ID</th>
                            <th scope="col">Expiration Date</th>
                            <th scope="col">Activation Status</th>
                            <th scope="col">Activation Date</th>
                            <th scope="col">License Type</th>
                            <th scope="col">License Key</th>
                            <th scope="col">OTP</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.licenseData.map((data, i)=>(
                            <tr style={{fontSize: '12px'}}>
                                <td>{i+1}</td>
                                <td>{data.organization?data.organization:'-'}</td>
                                <td title={data.vast_id?data.vast_id:'-'}>{data.vast_id?`${data.vast_id.slice(0,7)}...`:'-'}</td>
                                <td>{data.validity?data.validity:'-'}</td>
                                <td>{data.activation_status?'Activated':'Not Activated'}</td>
                                <td>{data.activation_date?Moment(data.activation_date).format("MMM DD YYYY, HH:mm:ss"):'-'}</td>
                                <td>{data.license_type?data.license_type:'-'}</td>
                                <td title={data.license_key?data.license_key:'-'}>{data.license_key?`${data.license_key.slice(0,7)}...`:'-'}</td>
                                <td>{data.otp?data.otp:'-'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            
        )
    }
}

// const


export default connect(Dashboard)

