import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Login from "../src/login/login";
import HomePage from "../src/HomePage/homePage";
import { Dashboard } from "./Dashboard/dashboard";
import AdminLayout from "./layout/AdminLayout";
import './App.css'
import axios from 'axios';


// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'admin-lte/dist/css/AdminLTE.css';
// import 'admin-lte/dist/css/skins/_all-skins.css'
// import '@fortawesome/fontawesome-free/css/all.css'
import getCookie from "./getCookie";

let csrfToken;

class App extends Component {
  // componentDidMount() {
  //   const userData = {
  //     email: "",
  //     password: ""
  //   }
  //   csrfToken = getCookie("csrftoken");
  //   // fetch(`/license/login/`, {
  //   //     method: "POST",
  //   //     headers: {
  //   //       "Content-Type": "application/json",
  //   //       "X-CSRFToken": csrfToken,
  //   //     },
  //   //     body: JSON.stringify(userData)
  //   //   })
  //   fetch(`/license/login/`, {
  //     method: 'POST', // or 'PUT'
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     // mode: 'cors',
  //     credentials: 'include',
  //     body: JSON.stringify(userData),
  // })
  // .then(res => console.error(res))
  //   // axios.post(`/license/login/`, {userData})
  // }

  render() {
    return (
      // <div>here is App</div>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/" component={Login} />
        <RouteWithLayout layout={AdminLayout} exact path="/home" component={HomePage} />
        <RouteWithLayout layout={AdminLayout} exact path="/dashboard" component={Dashboard} />
      </Switch>
    )
  }
}

const RouteWithLayout = ({ layout, component, ...rest }) => {
  return (
    <Route {...rest} render={(props) =>
      React.createElement(layout, props, React.createElement(component, props))
    } />
  );
}

export default withRouter(App);
